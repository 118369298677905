//User type
exports.gc_mz_siteAdmin = 1;
exports.gc_mz_zoneAdmin = 2;
exports.gc_mz_tailorDesigner = 3;
exports.gc_mz_tailorAssistants = 4;
exports.gc_mz_customers = 5;

//Device type -  default = 0
exports.Android = 1;
exports.Ios = 2;

//Activation Status - default = 1 active
//Activation Status   1 - Active, 2 - Deactivate
exports.Default = 0;
exports.Active = 1;
exports.inActive = 2;

exports.hideContent = 0;

// services
exports.gc_txt_var_all_services = 0;
exports.gc_txt_var_trending_design = 1;
exports.gc_txt_var_upload_design = 2;
exports.gc_txt_var_boutiques = 3;
exports.gc_txt_var_tailor_services = 4;
exports.gc_txt_var_readymades = 5;

exports.gc_tdn_os_measurement_unassign = 0;
exports.gc_tdn_os_measurement_assign = 1;
exports.gc_tdn_os_measurement_completed = 2; // un accepted order TD

exports.gc_tdn_os_order_accepted_TD = 3; // accepted order TD
exports.gc_tdn_os_ready_for_delivery = 4; // unassigned collection
exports.gc_tdn_os_assigned_collection = 5; // assigned collection

// gone from list TD if same zone -> deliver, if diff -> hub , assign delivery TA ID // the below are shown in the assigned delivery ( with the condition of delivery_owner id != 0 )
exports.gc_tdn_os_measurement_order_collected = 6; // move to delivery tab, diff goto hub tab, same goto delivery tab

//deliver to customer
exports.gc_tdn_os_measurement_order_home = 7; // show return btn in customer app

// products currently in hub
exports.gc_tdn_os_measurement_order_in_hub = 8; // these order are shown in the un assigned delivery ( with the condition of delivery_owner_id = 0 )

// to the destination zone move to unassigned delivery
exports.gc_tdn_os_measurement_order_submittted_in_hub = 9; //Not in use for mobile application
exports.gc_tdn_os_order_to_be_kept_in_hub = 10; //Not in use for mobile application

exports.gc_tdn_os_unconfirmed = 999; // Pending Orders
exports.gc_tdn_os_cancelled = 1000; // Cancelled Orders

// penality refund
exports.gm_penality = 1;
exports.gm_refund = 2;

// Cashflow Diretion
exports.gm_inward = 1;
exports.gm_outward = 2;


// Ac Details
exports.gm_designer = 1;
exports.gm_mazata = 2;

//to be collected
exports.gc_tbc_na = 0; //Nothing
exports.gc_tbc_measurement = 1; //Measurement
exports.gc_tbc_samplecloth = 2; //Sample cloth
exports.gc_tbc_orderData = ["NA", "Measurement", "Sample Cloth"];

//Discount Offered By
exports.gc_dob_options = ["Mazata", "Designers"];

//Style Attribute Dropdown
exports.gc_sad_options = ["NA", "Single Selection", "Multiple Selection", "Text Value"];

// reference Table Image Type
exports.gc_ir_variant_image = 1; //Product
exports.gc_ir_campaign_image = 2; //Measurement image
exports.gc_ir_UOD_order = 3; //Upload Own Design
exports.gc_ir_variant_image_tailoringService = 4; // tailoring service image

// Taillor Assistance Role
exports.gc_ta_role_un_assigned = 0;
exports.gc_ta_role_measurement = 1;
exports.gc_ta_role_collection_delivery = 2;
exports.gc_ta_role_both = 3;
exports.gc_ta_role_out_of_zone = 4;

//payment status
exports.gc_ps_payment_not_initiated = 0;
exports.gc_ps_payment_success = 1;
exports.gc_ps_payment_failed = 2;

//payment Mode
exports.gc_ps_payment_mode_cod = 0;
exports.gc_ps_payment_mode_online = 1;

//Customer Query Type
exports.gc_cqt_general = 0;
exports.gc_cqt_owndesi = 1;
exports.gc_cqt_orders = 2;
