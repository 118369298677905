import Axios from "axios";
import { toast } from "react-toastify";
toast.configure();
// Add a request interceptor
Axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("auth-token");
    if (token) {
      config.headers["x-auth-token"] = token;
    } else {
      config.headers["x-auth-token"] = "";
    }

    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  function (response) {
     
     
    if(!response.data.success){
      toast.warn(response.data.message);
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
      toast.warn(error.response.data.message);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export { Axios };
